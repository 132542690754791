import React, { useState } from "react"
import { Link } from "gatsby"
import {
  Header,
  BlogCardSmall,
  BlogCardSmallExpanded,
  ButtonPrimary,
  Footer,
  NewsletterBig,
  NewsletterSmall,
  Subtitle,
} from "../components/index.js"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Helmet from "react-helmet"
import Favicon from "../img/favicon.ico"
import "../styles/app.css"
import { graphql } from "gatsby"
import { FaRegClock } from "react-icons/fa"
import Img from "gatsby-image"
import ImgEn from "../imgs/en.png"
import ImgDe from "../imgs/de.png"

export default function BlogPostDe({ pageContext, data, lang = "en" }) {
  const post = pageContext

  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false)

  function handleNavBtnClick() {
    setIsHamburgerClicked(prevState => {
      return !prevState
    })
  }

  const mobileNavBarStyle = {
    left: `${isHamburgerClicked ? "10px" : "10000px"}`,
  }

  console.log("Popular:")
  console.log(post.popular)
  console.log("Trending:")
  console.log(post.trending)

  const slug = `https://www.getsofort.com/${post.slug}`
  const slugEn = `https://www.getsofort.com/${post.slugEn}`
  console.log("SLUG: " + slug)
  console.log("SLUG EN: " + slugEn)

  return (
    <>
      <Helmet>
        <html lang="de" />
        <title>{post.metaTitle}</title>
        <meta name="description" content={post.metaDescription} />
        <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link rel="alternate" href={slug} hreflang="de" />
        <link rel="alternate" href={slugEn} hreflang="en" />
        <link rel="alternate" href={slugEn} hreflang="x-default" />
      </Helmet>
      <Header lang="de" />

      <div className="navigation">
        <div
          role="presentation"
          className="hamburger-icon"
          onClick={handleNavBtnClick}
          onKeyDown={handleNavBtnClick}
        >
          <div className="hamburger-icon-line"></div>
          <div className="hamburger-icon-line"></div>
          <div className="hamburger-icon-line"></div>
        </div>
        <ul style={mobileNavBarStyle}>
          <li>
            <Link to="/de">Home</Link>
          </li>
          <li>
            <Link to="/de/#about-sofort">Über Sofort</Link>
          </li>
          <li>
            <Link to="/de/#online-casinos">Online Casinos</Link>
          </li>
          <li>
            <Link to="/de/#deposit">Deposit</Link>
          </li>
          <li>
            <Link to="/de/#cashouts">Cashouts</Link>
          </li>
          <li>
            <Link to="/de/#faq">FAQ</Link>
          </li>
          <li>
            <Link to="/de/blog">Blog</Link>
          </li>
        </ul>
      </div>
      <div className="langs">
        <Link to="/">
          <Img src={ImgEn} alt="English" />
        </Link>
        <Link to="/de">
          <Img src={ImgDe} alt="Deutsch" />
        </Link>
      </div>

      <div className="grid">
        <div className="left blog-post">
          <Img
            className="blog-card-small-img"
            fluid={{ ...post.banner.fluid, aspectRatio: 16 / 9 }}
            alt={post.banner.title}
          />

          {documentToReactComponents(post.content, {
            renderNode: {
              [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img
                  style={{
                    minWidth: "100%",
                    marginTop: "20px",
                    marginBottom: "0px",
                    maxWidth: "100%",
                  }}
                  src={`${node.data.target.fields.file["en-US"].url}?w=800`}
                  alt={node.data.target.fields.title["en-US"]}
                />
              ),
            },
          })}

          <div className="post-date-and-button">
            <p className="blog-card-small-date" style={{ paddingTop: "20px" }}>
              <FaRegClock className="clock-icon" />
              {post.published}
            </p>

            <Link
              className="go-back-link blog-card-big-button"
              to="/de"
              style={{
                maxWidth: "150px",
                padding: "6px 0px",
                marginTop: "-20px",
              }}
            >
              Zurück
            </Link>
          </div>
        </div>
        <div className="right">
          <NewsletterSmall lang="de" />
          <Subtitle>Beliebteste Nachrichten / Blogs</Subtitle>
          {post.popular.edges[0].node.post.map(node => {
            const slug = `/de/${node.slug}`
            return (
              <BlogCardSmall
                slug={slug}
                title={node.title}
                description={documentToReactComponents(node.description.json)}
                date={node.published}
                alt={node.title}
                img={node.banner && node.banner.fluid ? node.banner : null}
              />
            )
          })}
        </div>
      </div>
      <div className="bottom-posts-and-subtitle">
        <Subtitle>TRENDIGE NACHRICHTEN</Subtitle>
        <div className="bottom-posts">
          {post.trending.edges[0].node.post.map(node => {
            const slug = `/de/${node.slug}`
            return (
              <BlogCardSmallExpanded
                slug={slug}
                title={node.title}
                description={`${node.description.json.content[0].content[0].value.substring(
                  0,
                  200
                )}...`}
                date={node.published}
                alt={node.title}
                img={node.banner && node.banner.fluid ? node.banner : null}
              />
            )
          })}
        </div>
      </div>
      <ButtonPrimary />
      <NewsletterBig lang="de" />
      <Footer lang="de" />
    </>
  )
}

export const query = graphql`
  query {
    allImageSharp {
      nodes {
        id
        fluid(maxWidth: 2000) {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
    recentPosts: allContentfulPost(
      limit: 5
      sort: { fields: [published], order: DESC }
    ) {
      nodes {
        id
        slug
        published(formatString: "DD/MM/YYYY - h:m A")
        title
        description {
          json
        }
        content {
          json
        }
        category {
          name
        }
        banner {
          fluid(maxWidth: 2000) {
            base64
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
    }
    popularPosts: allContentfulPost(limit: 6) {
      nodes {
        id
        slug
        published(formatString: "DD/MM/YYYY - h:m A")
        title
        description {
          json
        }
        content {
          json
        }
        category {
          name
        }
        banner {
          fluid(maxWidth: 2000) {
            base64
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
    }
    trendingPosts: allContentfulPost(limit: 3) {
      nodes {
        id
        slug
        published(formatString: "DD/MM/YYYY - h:m A")
        title
        description {
          json
        }
        content {
          json
        }
        category {
          name
        }
        banner {
          fluid(maxWidth: 2000) {
            base64
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
    }
  }
`
